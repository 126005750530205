<template>
  <div>
    <v-row
      v-if="image"
      align="center"
      justify="center"
      class="pt-5"
    >
      <v-img
        :src="require(`@/assets/robot.png`)"
        max-width="180"
        contain
      ></v-img>
    </v-row>
    <v-row class="py-5">
      <v-col class="text-center">
        <h1
          v-if="title"
          class="display-3 primary--text mb-5 font-weight-black"
        >
          {{title}}
        </h1>
        <p class="wee-text-muted">{{message ? message : $t('error.dataNotfound')}}</p>
      </v-col>

    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    }
  }
};
</script>